<template>
	<tr v-if="$vuetify.breakpoint.smAndUp">
		<td :class="['px-1']" v-for="(column, index) in headers" :key="index">
			<div v-if="column.value == 'data-table-select'" class="text-center">
				<v-btn
					icon
					outlined
					color="primary"
					ref="refreshButton"
					@click.stop="reset"
					:disabled="!Object.values(value).some((i) => !!i)"
					:loading="timeout != null"
				>
					<v-icon>mdi-filter-off</v-icon>
				</v-btn>
			</div>

			<div v-if="column.filterable == false"></div>

			<v-select
				v-else-if="column.dataType == 'select'"
				:value="value[column.value]"
				@input="v => update(column.value, v)"
				:items="
					items
						.map((c) => c[column.value])
						.filter((v) => !!v)
						.sort()
				"
				:label="column.text"
				multiple
				dense
				single-line
				hide-details
				class="hide-line"
			>
				<template v-slot:prepend-item>
					<v-list-item ripple @click="toggle(column.value)">
						<v-list-item-action>
							<v-icon
								:color="
									value[column.value] && value[column.value].length > 0
										? 'secondary'
										: ''
								"
								v-text="getSelectAllIcon(column.value)"
							></v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>Seleccionar todo</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-divider class="mt-2"></v-divider>
				</template>
				<template v-slot:selection="{ attrs, item, selected, parent }">
					<v-chip
						class="mr-1 mb-1"
						v-bind="attrs"
						:input-value="selected"
						:value="item"
						small
						close
						@click:close="parent.selectItem(item)"
					>{{ item }}</v-chip>
				</template>
			</v-select>
			
			<v-text-field
				v-else-if="column.dataType == 'text'"
				:value="value[column.value]"
				@input="v => update(column.value, v)"
				type="text"
				:placeholder="column.text"
				dense
				hide-details
				single-line
				class="hide-line"
			/>
			<v-menu
				v-else-if="column.dataType == 'date'"
				:close-on-content-click="false"
				transition="scale-transition"
				offset-y
				min-width="auto"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-text-field
						readonly
						v-bind="attrs"
						v-on="on"
						:value="value[column.value]"
						@input="v => update(column.value, v)"
						:placeholder="column.text"
						dense
						hide-details
						clearable
						class="hide-line"
					/>
				</template>
				<v-date-picker :value="value[column.value]"
				@input="v => update(column.value, v)" no-title scrollable dense range>
					<v-spacer />
					<v-btn small text color="primary" @click="clearKey(column.value)">Borrar</v-btn>
				</v-date-picker>
			</v-menu>

			<v-btn-toggle v-else-if="column.dataType == 'bool'" borderless group :value="value[column.value]" @change="v => update(column.value, v)">
				<v-btn small :value="false">No</v-btn>
				<v-btn small :value="true">Sí</v-btn>
			</v-btn-toggle>
		</td>
	</tr>
</template>

<script>
export default {
	props: {
		headers: Array,
		items: Array,
		value: Object,
	},
	data() {
		return {
			filters: {},
			unwatch: null,
			sheet: false,
			timeout: null,
		};
	},
	// computed: {
	// 	filters() {
	// 		return { ...this.value }
	// 	}
	// },
	methods: {
		update(key, value) {
			clearTimeout(this.timeout)
			this.timeout = setTimeout(() => {
				this.$emit('input', { ...this.value, [key]: value })
				this.timeout = null
			}, 500)
    },
		closeFilters() {
			if (this.$refs.scroll.scrollTop == 0) this.sheet = false;
		},
		clearKey(key) {
			// this.filters[key] = null;
			this.update(key, null)
		},
		reset() {
			// this.filters = {};
      this.$emit('input', Object.create(Object.prototype))
		},
		toggle(key) {
			const items = [
				...new Set(this.items.map((c) => c[key]).filter((v) => !!v)),
			];

			if (
				this.value[key] == null ||
				this.value[key] == undefined ||
				this.value[key].length < items.length
			) {
				this.$set(this.value, key, items);
			} else if (this.value[key].length == items.length)
				this.value[key] = null;
		},
		getSelectAllIcon(key) {
			if (!this.value[key]) return "mdi-checkbox-blank-outline";

			const itemsL = [
				...new Set(this.items.map((c) => c[key]).filter((v) => !!v)),
			].length;

			if (this.value[key].length == itemsL) return "mdi-close-box";
			return "mdi-minus-box";
		},
	},
	created() {
		// this.unwatch = this.$watch(
		// 	"filters",
		// 	() => {
		// 		clearTimeout(this.timeout);
		// 		this.timeout = setTimeout(() => {
		// 			let temp = Object.fromEntries(
		// 				Object.entries(this.filters).filter(([_, v]) => v != null)
		// 			);
		// 			this.$emit("input", temp);
		// 		}, 500);
		// 	},
		// 	{ deep: true }
		// );
	},
	beforeDestroy() {
		// this.unwatch();
	},
};
</script>

<style>
.hide-checkbox .v-input--selection-controls__input {
	display: none;
}
.hide-line .v-input__slot::before {
	display: none;
}
.hide-line .v-text-field__slot::before {
	display: none;
}
.v-btn-toggle--group > .v-btn.v-btn {
	margin: 0px !important;
}
</style>